<template>
  <pz-form-flujo
    v-model="formulario2['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_empresa.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      :model="formulario2"
      status-icon
      :rules="rules_formulario2"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="rut"
            :label="$t('forms_flujo.form2_empresa.UY.doc_number')"
            :error="errors.get('rut')"
          >
            <el-input
              v-model="formulario2.rut"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_empresa.UY.doc_number')"
              @change="errors.clear('rut')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="comienzoactividad"
            :label="$t('forms_flujo.form2_empresa.UY.activity_start')"
            :error="errors.get('comienzoactividad')"
          >
            <el-date-picker
              v-model="formulario2.comienzoactividad"
              type="date"
              :placeholder="$t('forms_flujo.form2_empresa.UY.activity_start')"
              format="dd-MM-yyyy"
              value-format="dd-MM-yyyy"
              @change="errors.clear('comienzoactividad')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="company"
            :label="$t('forms_flujo.form2_empresa.UY.company')"
            :error="errors.get('company')"
          >
            <el-input
              v-model="formulario2.company"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_empresa.UY.company')"
              @change="errors.clear('company')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="rubro_id"
            :label="$t('forms_flujo.form2_empresa.UY.activity_type')"
            :error="errors.get('rubro_id')"
            class="bordered"
          >
            <el-select
              v-model="formulario2.rubro_id"
              :placeholder="$t('forms_flujo.form2_empresa.UY.activity_type')"
            >
              <el-option
                v-for="item in rubros"
                :key="item.id"
                :label="item.rubro"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="razonsocial"
            :label="$t('forms_flujo.form2_empresa.UY.company_name')"
            :error="errors.get('razonsocial')"
          >
            <el-input
              v-model="formulario2.razonsocial"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_empresa.UY.company_name')"
              @change="errors.clear('razonsocial')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="direccion"
            :label="$t('forms_flujo.form2_empresa.UY.address')"
            :error="errors.get('direccion')"
          >
            <el-input
              v-model="formulario2.direccion"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_empresa.UY.address')"
              @change="errors.clear('direccion')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :span="24"
          class="center-block text-center"
        >
          <pz-button
            :button-name="$t('forms_flujo.form2_empresa.UY.add_partner')"
            icon="el-icon-circle-plus-outline"
            @click="addSocios"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          v-if="formulario2.socios.length>0"
          :span="24"
          class="el-row"
          style="text-align : center;width:100%; color:white; font-size: medium;padding-top: 20px;"
        >
          <table class="table">
            <tbody>
              <tr
                v-for="(socio, index) in formulario2.socios"
                :key="index"
              >
                <td>
                  <el-form-item
                    :key="socio.key"
                    :label="$t('forms_flujo.form2_empresa.UY.partner.doc_number')"
                    :prop="'socios.' + index + '.documento'"
                    :rules="rules_socios.documento"
                  >
                    <el-input
                      v-model="socio.documento"
                      type="text"
                      auto-complete="no"
                      :placeholder="$t('forms_flujo.form2_empresa.UY.partner.doc_number')"
                    />
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    :key="socio.key"
                    :label="$t('forms_flujo.form2_empresa.UY.partner.name')"
                    :prop="'socios.' + index + '.nombre'"
                    :rules="rules_socios.nombre"
                  >
                    <el-input
                      v-model="socio.nombre"
                      type="text"
                      auto-complete="no"
                      :placeholder="$t('forms_flujo.form2_empresa.UY.partner.name')"
                    />
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    :key="socio.key"
                    :label="$t('forms_flujo.form2_empresa.UY.partner.surename')"
                    :prop="'socios.' + index + '.apellido'"
                    :rules="rules_socios.apellido"
                  >
                    <el-input
                      v-model="socio.apellido"
                      type="text"
                      auto-complete="no"
                      :placeholder="$t('forms_flujo.form2_empresa.UY.partner.surename')"
                    />
                  </el-form-item>
                </td>
                <td class="aling_midle">
                  <el-button
                    type="danger"
                    class="el-dele-socios"
                    icon="el-icon-delete"
                    circle
                    @click="removeSocios(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<style type="scss">
.el-dele-socios {
  font-size: 14px;
  padding: 8px !important;
}

.aling_midle {
  vertical-align: middle!important;
}
</style>
<script>
import {Cedula, required, Rut} from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css'
import {getDepartamentos, getRubros} from '@/routes/api/resources';
import baseForm2 from "../baseForm2";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzButton from "@/commons/forms/Buttons/PzButton";

export default {
    name: 'PzCompanyFormExtUy',
    components: {PzButton, PzFormFlujo},
    mixins: [baseForm2],
    data() {
        return {
            loading: false,
            marital_status: [],
            rubros: [],
            sociosActivo: false,
            centerDialogVisible: false,
            departamentos: [],
            formulario2: {
                company: '',
                razonsocial: '',
                rut: '',
                comienzoactividad: '',
                direccion: '',
                rubro_id: '',
                ref_empresa: '',
                socios: [],
            },
            disable: true,
            rules_formulario2:
                {
                    company: [required('')],
                    razonsocial: [required('')],
                    rut: [Rut('')],
                    comienzoactividad: [required('')],
                    rubro_id: [required('')],
                },
            rules_socios: {
                nombre: [required('')],
                apellido: [required('')],
                documento: [Cedula('')]
            }
        };
    },
    beforeMount() {
        let depto = getDepartamentos().then((res) => {
            this.departamentos = res.data;
        }).catch(() => {
            this.departamentos = [];
        });
        let rubros = getRubros().then((res) => {
            this.rubros = res.data;
        }).catch(() => {
            this.rubros = [];
        });
        Promise.all([rubros, depto]).then(() => {
        });
    },
    methods: {
        removeSocios: function (index) {
            this.formulario2.socios.splice(index, 1);
        },
        addSocios() {
            this.sociosActivo = true;
            //var elem = document.createElement('tr');
            this.formulario2.socios.push({
                nombre: '',
                apellido: '',
                documento: '',
                key: ''
            });
        },
    },
}
</script>

